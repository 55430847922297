.upcomingcard-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  margin-top: 3px;
  justify-content: center;
  text-align: center;
}

.upcomingcard-title {
  font-size: 1.2rem;
  color: white;
  margin-top: 5px;
}
@media screen and (max-width: 359px) {
  .upcomingcard-card {
    height: 90px;
    width: 150px;
  }
}
@media screen and (min-width: 360px) {
  .upcomingcard-card {
    height: 100px;
    width: 167px;
  }
}
@media screen and (min-width: 380px) {
  .upcomingcard-card {
    height: 100px;
    width: 175px;
  }
}
@media screen and (min-width: 390px) {
  .upcomingcard-card {
    height: 110px;
    width: 180px;
  }
}
@media screen and (min-width: 440px) {
  .upcomingcard-card {
    height: 130px;
    width: 200px;
  }
}
@media screen and (min-width: 460px) {
  .upcomingcard-card {
    height: 130px;
    width: 220px;
  }
}
@media screen and (min-width: 480px) {
  .upcomingcard-card {
    height: 150px;
    width: 225px;
  }
}
@media screen and (min-width: 580px) {
  .upcomingcard-card {
    height: 150px;
    width: 290px;
  }
}
@media screen and (min-width: 650px) {
  .upcomingcard-card {
    height: 150px;
    width: 310px;
  }
}
@media screen and (min-width: 700px) {
  .upcomingcard-card {
    height: 150px;
    width: 330px;
  }
}
@media screen and (min-width: 760px) {
  .upcomingcard-card {
    height: 150px;
    width: 350px;
  }
}
@media screen and (min-width: 820px) {
  .upcomingcard-card {
    height: 180px;
    width: 270px;
  }
  .upcomingcard-title {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 990px) {
  .upcomingcard-card {
    height: 180px;
    width: 320px;
  }
}
@media screen and (min-width: 1100px) {
  .upcomingcard-card {
    height: 200px;
    width: 310px;
  }
}
@media screen and (min-width: 1190px) {
  .upcomingcard-card {
    height: 200px;
    width: 370px;
  }
}

@media screen and (min-width: 1270px) {
  .upcomingcard-card {
    height: 200px;
    width: 310px;
  }
}
@media screen and (min-width: 1400px) {
  .upcomingcard-card {
    height: 200px;
    width: 340px;
  }
}
@media screen and (min-width: 1600px) {
  .upcomingcard-card {
    height: 230px;
    width: 360px;
  }
}
@media screen and (min-width: 1710px) {
  .upcomingcard-card {
    height: 240px;
    width: 400px;
  }
}
@media screen and (min-width: 1750px) {
  .upcomingcard-card {
    height: 240px;
    width: 430px;
  }
}
.upcomingcard-card {
  border-radius: 4px;
  transition: all 0.5s;
  background-position: center;
  background-size: cover;
}
