.genresdiv,
.formatsdiv,
.seasonsdiv,
.statusesdiv {
  transition: all 300ms ease;
  width: 95%;
  margin: 0 auto;
  display: grid;
  align-items: center;
  gap: 30px;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  column-gap: 10;
}
.genre:hover,
.format:hover,
.status:hover,
.season:hover {
  transform: scale(1.05);
  transition: 300ms ease;
}
.genre,
.format,
.status,
.season {
  cursor: pointer;
  background-color: rgb(0, 0, 0, 0.5);
  background-size: cover;
  background-position: center;
  text-align: center;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  height: 155px;
  width: 270px;
  border-radius: 5px;
}
.genres-title,
.formats-title,
.seasons-title,
.statuses-title {
  color: white;
  margin-bottom: 10px;
  font-size: 2.5rem;
  margin-top: 40px;
  margin-left: 2.6%;
}
.genres-title {
  margin-top: 80px;
}
.statusesdiv {
  padding-bottom: 50px;
}
@media screen and (max-width: 560px) {
  .genres-title,
  .formats-title,
  .seasons-title,
  .statuses-title {
    margin-left: 4.2%;
  }
  .genresdiv,
  .formatsdiv,
  .statusesdiv,
  .seasonsdiv {
    width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(41vw, 1fr));
  }
  .genresdiv div,
  .formatsdiv div,
  .statusesdiv div,
  .seasonsdiv div {
    gap: 10px;
    row-gap: 0px;
    column-gap: 0px;
    width: 46vw;
    height: 120px;
  }
}
@media screen and (max-width: 375px) {
  .genresdiv,
  .formatsdiv,
  .statusesdiv .seasonsdiv {
    width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(40vw, 1fr));
  }
  .genresdiv div,
  .formatsdiv div,
  .statusesdiv div,
  .seasonsdiv div {
    width: 46vw;
  }
}
@media screen and (max-width: 300px) {
  .genresdiv div,
  .formatsdiv div,
  .statusesdiv div,
  .seasonsdiv div {
    width: 90vw;
  }
}
